<template>
  <base-dialog ref="dialog">
    <template #title>
      {{ $t('actions.add') }} {{ $tc('user.name', 1) }}</template
    >
    <template #subtitle>{{
      userGroup.attributes.name &&
        `${$tc('userGroup.company', 1)}: ${userGroup.attributes.name}`
    }}</template>
    <template #content>
      <BaseInput
        v-model="email"
        type="email"
        :rules="emailRules"
        placeholder="Eg. antonio@supplier.com"
        @input="myUser = false"
      />
    </template>
    <template #actions
      ><v-btn
        :loading="isLoading"
        depressed
        :disabled="!validEmail"
        @click="onClickAdd"
      >
        <v-icon left>mdi-plus</v-icon> {{ $t('actions.add') }}
      </v-btn></template
    >
  </base-dialog>
</template>

<script>
import BaseDialog from '@/components/BaseDialog.vue'
import BaseInput from '@/components/fields/BaseFieldInput.vue'
import { mapActions, mapGetters } from 'vuex'
import { USER } from '@/models/user'
import { ORGANIZATION_ID } from '../../common/config'
export default {
  components: { BaseDialog, BaseInput },
  props: {
    category: {
      type: String,
      default: 'supplier'
    },
    groupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      email: null,
      emailRules: [
        v => !!v || `E-mail ${this.$t('hints.isRequired')}`,
        v =>
          /[^@]+@[^.]+\..*/.test(v) || `E-mail ${this.$t('hints.mustBeValid')}`
      ],
      myUserGroup: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      userGroup: 'userGroup/userGroup'
    }),
    validEmail() {
      return !this.myUser && this.email && /[^@]+@[^.]+\..*/.test(this.email)
    }
  },
  methods: {
    ...mapActions({
      filterUser: 'user/filter',
      createUser: 'user/create',
      updateUser: 'user/update',
      fetchUserGroup: 'userGroup/fetch',
      editUserOrganization: 'user/editUserOrganization'
    }),

    async onClickAdd() {
      // Search for user by email
      const response = await this.filterUser({
        search: 'true',
        email: this.email
      })
      //Get first user from response
      const user = response.length && response[0]
      // If User exist, add to organization. Otherwise create a new one
      if (!user) {
        const user = USER(this.category)
        user.attributes.email = this.email
        user.attributes.groupIds.push(this.groupId)
        await this.createUser(user)
        await this.fetchUserGroup(this.groupId)
      } else {
        const myUser = this.belongToOrganizationAlready(user)
        if (!myUser) await this.addUserToOrganization(user)
        await this.addUserToGroup(user)
        await this.fetchUserGroup(this.groupId)
      }

      this.$refs.dialog.onCloseDialog()
    },
    belongToOrganizationAlready(user) {
      if (!user) return
      return user.attributes.organizationId === ORGANIZATION_ID()
    },
    async addUserToOrganization(user) {
      await this.editUserOrganization({
        id: `${user.id}/organization`,
        type: 'user',
        attributes: { organization: 'add' }
      })
    },

    async addUserToGroup(user) {
      const groupIds = [...user.attributes.groupIds, this.groupId]
      const userObj = {
        id: user.id,
        attributes: { groupIds }
      }
      await this.updateUser(userObj)
    }
  }
}
</script>

<style scoped></style>
